import { enableProdMode, importProvidersFrom, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { AppComponent } from './app/app.component';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { LoaderService } from './app/shared/services/loader/loader.service';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { HttpConfigInterceptor } from './app/shared/services/interceptor/http-config.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './app/translation-paginator/translate-paginator';
import { provideRouter, Router } from '@angular/router';
import { routes } from './app/app-routing';
import { MatSnackBarModule } from '@angular/material/snack-bar';

if (environment.production || environment.staging || environment.development) {
  let envVariable = environment.production ? 'Production' : (environment.staging ? 'Staging' : 'Development');

  Sentry.init({
    // update sentry project DSN value here
    dsn: "https://fae585bd6c7a3d862e1f0ee1891a5032@o478834.ingest.sentry.io/4505914132201472",

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      // Registers and configures the Replay integration,
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", "https://yourserver.io/api"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Set which environment the error generated
    environment: envVariable,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.jsonPath, '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      BrowserModule, 
      ToastrModule.forRoot({
        timeOut: 2500,
        extendedTimeOut: 1000,
        easing: 'ease-in',
        easeTime: 500,
        enableHtml: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        tapToDismiss: true,
        positionClass: 'toast-bottom-center',
        preventDuplicates: false
      }),
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: httpTranslateLoader,
              deps: [HttpClient],
          },
      }),
      // SnackBarComponent
      MatSnackBarModule
    ),
    DatePipe,
    LoaderService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpConfigInterceptor,
        multi: true,
    },
    {
        provide: MatPaginatorIntl,
        useClass: CustomMatPaginatorIntl,
    },
    {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            // Will show dialog for user feedback
            showDialog: false,
        }),
    },
    {
        provide: Sentry.TraceService,
        deps: [Router],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => { },
        deps: [Sentry.TraceService],
        multi: true,
    },

    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(routes)
  ]
});