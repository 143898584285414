import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconModule, TranslateModule]
})

export class SnackBarComponent implements OnInit {
  type = 1;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  ngOnInit() {
  }

  get getIcon() {
    switch (this.data.type) {
      case 'success':
        return { type: this.data.type, icon: 'check' };
      case 'error':
        return { type: this.data.type, icon: 'faults' };
      case 'warn':
        return { type: this.data.type, icon: 'warning_outline' };
      case 'info':
        return { type: this.data.type, icon: 'info' };
    }
  }
}
