import { ResponseData } from './shared/models/lookup/response-data.interface';
import { AuthService } from './authentication/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { MatIconRegisterService } from './shared/services/mat-icon-register/mat-icon-register.service';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  title = 'angular-master';
  user: any;
  userType = 'user';

  constructor(
    public translate: TranslateService,
    private authservice: AuthService,
    private iconRegister: MatIconRegisterService
  ) {
    iconRegister.RegisterIcon()
    translate.setDefaultLang('en');
    if (localStorage.getItem('userType') == null) {
      localStorage.setItem('userType', this.userType);
    }

  }

  ngOnInit(): void {
    let lang = localStorage.getItem('language')
    let selected_lang = lang ? JSON.parse(lang) : '';
    let code:string =selected_lang?.code?.substring(0,2)
    selected_lang ? this.translate.use(code?.toLowerCase()) : ''
  }
}
