import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, of } from "rxjs";
import { DataService } from "src/app/shared/services/data/data.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  $baseUrl = environment.baseUrl;
  currentUser: any = localStorage.getItem('ABCUserData');
  error_data: BehaviorSubject<any> = new BehaviorSubject('');

  constructor(private http: HttpClient,
    private router: Router,
   ) { }

  loginWithSSO() {
    let url = '/auth/login/';
    return this.getData(url);
  }

  loginWithToken(data) {
    let url = '/auth/login/?AuthCode=' + data.code + '&state=' + data.state;
    return this.postData(url, '');
  }



  // USER
  getEmailAvailability(email) {
    let url = "/users/email/availability/?email=" + email;
    return this.getData(url);
  }

  getUnameAvailability(uname) {
    let url = "/users/username/availability/?username=" + uname;
    return this.getData(url);
  }

  getPswdAvailability(pswd) {
    let url = "/users/password/validity/?password=" + pswd;
    return this.getData(url);
  }

  forgotPswd(uname) {
    let data = {
      username: uname,
    };
    let url = "/users/password/forgot/";
    return this.postData(url, data);
  }

  // login(data) {
  //   let url = "/login";
  //   let baseUrl = this.$baseUrl + url;
  //   let params = new HttpParams().set('LoginName', data);

  //   return this.http.post(baseUrl, {}, {headers: {
  //     "Content-Type": "application/json",
  //   }, params: params });
  //   // return this.postData(url, {});
  //   // return this.postData(url, data);
  // }

  resetUserPswd(data) {
    let url = "/users/password/reset/";
    return this.postData(url, data);
  }

  logout() {
    localStorage.removeItem("ifUserLoggedin");
    localStorage.removeItem("ABCUserData");
    // console.log("User Logged Out!");
    this.router.navigateByUrl("login");
  }

  // primary methods
  getData(url: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.Options());
  }

  getDataByParams(url: any, params) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.Options3(params));
  }

  postData(url: any, data: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, data, this.Options());
  }

  patchData(url: any, data: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.patch(baseUrl, data, this.Options());
  }

  // httpOptions
  Options() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return httpOptions;
  }

  // for img upload
  Options2() {
    const httpOptions = {
      headers: new HttpHeaders({}),
    };
    return httpOptions;
  }

  Options3(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: new HttpParams({ fromObject: params }),
    };
    return httpOptions;
  }
  Options4(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.currentUser.token}`
      }),
      params: new HttpParams({ fromObject: params }),
    };
    return httpOptions;
  }
  // login portal
  loginToPortal() {
    return this.http.get(this.$baseUrl + '/auth/login', this.Options());
  }
  byPass(param, data) {
    return this.http.post(this.$baseUrl + '/auth/login?authCode=' + param.authCode, data,);
  }
  logOut() {
    return this.http.get(this.$baseUrl + '/auth/logout', this.Options());
  }
}
