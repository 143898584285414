import { HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data/data.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { SnackBarComponent } from 'src/app/core/component/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/authentication/services/auth/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private router: Router,
    private dataService: DataService,
    private loaderService: LoaderService,
    public _snackBar: MatSnackBar,
    private _authService: AuthService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = request;
    let user = localStorage.getItem('ABCUserData');
    let currentUser = user ? JSON.parse(user) : null;


    let languageData = localStorage.getItem('language');
    let language = languageData ? JSON.parse(languageData) : null;


    if (currentUser) {
      authReq = this.addTokenHeader(request, currentUser.token, language?.code);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        console.log('error', error);
        //debugger
        this.loaderService.display(true);
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(authReq, next);
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          // console.log(error);
          this.customSnackBar('YourAccountDetailsWasUpdated', 'error');
          this.loaderService.display(false);
          setTimeout(() => {
            this.logout();
          }, 3000);

        } else if (error instanceof HttpErrorResponse && error.status === 409) {
          // console.log(error);
          this.loaderService.display(false);
          this.dataService.customSnackBar(error.error.data, 'error');
        } else if (error instanceof HttpErrorResponse && error.status === 400) {
          // console.log(error);
          this.loaderService.display(false);
          // this.dataService.handleError(error)
          this.customSnackBar('ErrorOccured', 'error');
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.dataService.refreshToken().pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;
          localStorage.setItem('ABCUserData', JSON.stringify(res.data));
          this.refreshTokenSubject.next(res.data.token);
          this.loaderService.display(false);
          return next.handle(this.addTokenHeader(request, res.data.token));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.loaderService.display(true);
          this.logout();
          return throwError(err);
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }




  private addTokenHeader(request: HttpRequest<any>, token: string, language?: string) {
    let headers = {
      Authorization: `Bearer ${token}`,
      'Time-zone': new Date().getTimezoneOffset().toString()
    };

    if (language) {
      headers['Accept-Language'] = language;
    }

    return request.clone({
      setHeaders: headers // This is where the error might be occurring
    });
  }

  private addLanguageHeader(request: HttpRequest<any>, language: string) {
    // console.log(token);
    // return request.clone({
    //   setHeaders: {
    //     'Accept-Language': language
    //   },
    // });

    return request.clone({ headers: request.headers.set('Accept-Language', language) });

  }
  logout() {
    this.loaderService.display(true);
    this.dataService.logout().subscribe({
      // console.log(res);
      next: (resp: any) => {
        if (resp.code === 200 || resp.code === 400) {
          window.location.href = resp.data;
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      error: (err: any) => {
        this.loaderService.display(false);
        localStorage.clear();
        sessionStorage.clear();
        // this.router.navigate(['/login']);
        if (err.error.code != 401) {
          this._authService.error_data.next(err.error);
          this.router.navigate(['/error']);
        }
        // console.log(err);
      },
      complete: () => {
        this.loaderService.display(false);
      }
    });
  }

  // Method to toast success/error messages (custom)
  customSnackBar(message: string, type: string) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: message,
        type: type,
      },
      duration: 3000,
    });
  }
}

