import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SnackBarComponent } from 'src/app/core/component/snack-bar/snack-bar.component';
import { Operator } from 'src/app/features/operator-box/models/operator.model';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../../models/lookup/response-data.interface';

@Injectable({
  providedIn: 'root',
})

export class DataService {
  $baseUrl = environment.baseUrl;
  user = localStorage.getItem('ABCUserData');
  currentUser = this.user ? JSON.parse(this.user) : null;
  statusColor = {
    0: {
      background: '#EDEDED',
      color: '#000000',
    },
    1: {
      background: '#FFC9C9',
      color: '#E14848',
    },
    2: {
      background: '#D3F0DB',
      color: '#01A747',
    },
    3: {
      background: '#FFEDBD',
      color: '#C7940C',
    },
    4: {
      background: '#FFC9C9',
      color: '#E14848',
    },
  }

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  customSnackBar(message: string, type: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: message,
        type: type,
      },
      duration: type === 'success' ? 3000 : 5000,
    });
  }

  dismiss() {
    this.snackBar?.dismiss()
  }

  // info api
  getInfo(params) {
    let url = '/info';
    return this.getDataByParams(url, params)
  }

  getOperator(params): Observable<ResponseData<Operator>> {
    let url = '/auth/operator';
    return this.getDataByParams(url, params) as Observable<ResponseData<Operator>>;
  }

  // refresh token api
  // refresh api
  refreshToken() {
    return this.http.get(this.$baseUrl + '/auth/refresh/');
  }

  // user logout
  logout() {
    return this.http.get(this.$baseUrl + '/auth/logout/');
  }


  // lookup apis
  getLabels(params) {
    let url = '/lookup/label';
    return this.getDataByParams(url, params);
  }

  getLabelOwner(params) {
    let url = '/lookup/labelowner';
    return this.getDataByParams(url, params);
  }

  getOperatorList(params) {
    let url = '/lookup/operator';
    return this.getDataByParams(url, params);
  }

  getComplianceList(params) {
    let url = '/lookup/compliance';
    return this.getDataByParams(url, params);
  }

  getRuleClassList(params) {
    let url = '/lookup/ruleclass';
    return this.getDataByParams(url, params);
  }

  getParamList(params) {
    let url = '/lookup/param';
    return this.getDataByParams(url, params);
  }


  // primary methods

  getData(url: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl);
  }

  getDataByParams(url: any, params: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.OptionsWithParam(params));
  }

  getDataByParamsAndLanguage(url: any, language: string, params: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.OptionsWithParamsAndLanguage(language,params));
  }

  deleteData(url: any, params: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.delete(baseUrl, this.OptionsWithParam(params));
  }

  postData(url: any, data: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, data);
  }

  postDataByParams(url: any, data: any, params: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, data, this.OptionsWithParam(params));
  }

  patchData(url: any, data: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.patch(baseUrl, data);
  }

  // for http calls with param
  OptionsWithParam(param) {
    const httpOptions = {
      headers: new HttpHeaders({
        language: 'en',
        'Time-zone': new Date().getTimezoneOffset().toString(),
      }),
      params: new HttpParams({ fromObject: param }),
    };
    return httpOptions;
  }

  OptionsWithParamsAndLanguage(language, params) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Accept-Language': language,
        language: 'en',
        'Time-zone': new Date().getTimezoneOffset().toString(),
      }),
      params: new HttpParams({ fromObject: params }),
    };
    return httpOptions;
  }
}
